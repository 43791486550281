import { render, staticRenderFns } from "./evidence-center.vue?vue&type=template&id=d4fb2fc6&scoped=true"
import script from "./evidence-center.vue?vue&type=script&lang=js"
export * from "./evidence-center.vue?vue&type=script&lang=js"
import style0 from "./evidence-center.vue?vue&type=style&index=0&id=d4fb2fc6&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4fb2fc6",
  null
  
)

export default component.exports